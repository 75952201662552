section {
  padding: 10px 0;
  width: 95%;
  margin: auto;
}

section h2 {
  font-size: 30px;
  padding: 0px 0 25px 0;
}

section ul li {
  list-style: none;
  text-align: start;
  border: 1px solid #764A3B;
  border-radius: 15px;
  padding: 0px 13px 5px 10px;
  display: flex;
  margin: 15px 0 15px 0;
  justify-content: space-between;
}

.espacamento {
  margin: 10px 0 15px 0;
}

span {
  font-family: "Myriad-SemiBold";
  font-size: 20px;
}

.fotoPadrao {
  border-radius: 10px;
  margin: 13px 0 0 25px;
}

.preço {
  font-family: "Myriad-SemiBold";
  margin: 13px 0 2px 0;
  font-size: 18px;
}

.noImage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
}

.modal-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  max-width: 95%;
  max-height: 100%;
}

.modal-content img {
  border-radius: 10px;
  width: 395px;
  height: 260px;
  object-fit: cover;
}

.modal-content button {
  list-style-type: none;
  margin-top: 10px;
  padding: 8px 15px;
  border-radius: 10px;
  background-color: #FDD5AF;
  font-family: "Myriad-Regular";
  font-size: 16px;
}