#bebidas img {
  display: none;
}

#bebidas .preço {
  margin-top: 11px;
}

#bebidas ul li { 
  display: block;
}

#bebidas .noImage {
  flex-direction: row;
  margin: 0 0 -10px 0;
}