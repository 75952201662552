.subTitulo {
  display: flex;
  justify-content: flex-end;
  font-weight: normal;
  font-family: "Myriad-SemiBold";
  margin-right: 15px;
  font-size: 18px;
}

/* #pratosPrincipais .preço {
  padding-top: 5px;
} */