#janta img {
  display: none;
}

#janta .preço {
  margin-top: 11px;
}

#janta ul li { 
  display: block;
}

#janta .noImage {
  flex-direction: row;
  margin: 0 0 -10px 0;
}

#janta h1 {
  font-size: 28px;
  padding-top: 7px;
}

#janta .valores h1 {
  font-weight: normal;
  font-size: 22px;
  padding-top: 0px;
}

#janta .valores {
  padding-top: 15px;
}

#janta .pratosBasicos, .pratosPremium {
  border: 1px solid #764A3B;
  border-radius: 15px;
  padding: 0 0 10px 0;
  margin: 15px 0 15px 0;
}

#janta .descricao {
  margin-bottom: 13px;
}