.menu-wrapper {
  white-space: nowrap;
  /* overflow: hidden; */
  overflow-x: auto;
  padding: 20px 10px 20px 10px;
}

.menu-wrapper::-webkit-scrollbar {
  display: none;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav li {
  display: inline-block;
  margin-right: 10px;
  border: 1px solid #FDD5AF;
  border-radius: 20px;
  padding: 8px 20px;
  background-color: #FDD5AF;
}

nav a {
  font-family: "Myriad-SemiBold";
  text-decoration: none;
  color: #764A3B;
}