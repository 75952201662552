#porcoes img {
  display: none;
}

#porcoes .preço {
  margin-top: 11px;
}

#porcoes ul li { 
  display: block;
}

#porcoes .noImage {
  flex-direction: row;
  margin: 0 0 -10px 0;
}