#bebidasQuentes .cafeLeite {
  border: 1px solid #764A3B;
  border-radius: 15px;
  display: flex;
  text-align: start;
  justify-content: space-between;
  padding: 0px 13px 5px 10px;
  margin: 15px 0 15px 0;
}

#bebidasQuentes .fotoCafe {
  border-radius: 10px;
  margin: 10px 0 0 25px;
  display: block;
}

#bebidasQuentes .posicaoPreco {
  padding-top: 35px;
}

#bebidasQuentes img {
  display: none;
}

#bebidasQuentes .preço {
  margin-top: 11px;
}

#bebidasQuentes ul li { 
  display: block;
}

#bebidasQuentes .noImage {
  flex-direction: row;
  margin: 0 0 -10px 0;
}