* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
}

@font-face {
  font-family: 'Myriad-Regular';
  src: url(assets/Fonts/MYRIADPRO-REGULAR.woff);
}

@font-face {
  font-family: 'Myriad-Bold';
  src: url(assets/Fonts/MYRIADPRO-BOLD.woff);
}

@font-face {
  font-family: 'Myriad-SemiBold';
  src: url(assets/Fonts/MYRIADPRO-SEMIBOLD.woff);
}

html {
  scroll-behavior: smooth;
  background-color: #fee9d4;
}

body {
  text-align: center;
  font-family: 'Myriad-Regular';
  background-color: #fee9d4;
  color: #764a3b;
}

header {
  padding: 20px 0 30px 0;
  line-height: 35px;
  font-size: 22px;
  font-family: 'Myriad-Bold';
}

footer {
  background-color: #fdd5af;
  padding: 10px;
  text-align: center;
}
